var tuoteHakuItems = {
    "data": [
        {
            "ean_code": "5051388097866",
            "code": "8692",
            "text": "Kaasuhitsaus / suojalasit Din 5 / 50 mm",
            "info": "Kaasuhitsaus / suojalasit Din 5 / 50 mm, sisältää tummat ja kirkkaat lasit",
            "main_category": "5. Hitsausmaskit ja -lasit",
            "down_category": "Kaasuhitsauslasi"
        },
        {
            "ean_code": "8712067818604",
            "code": "15130",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Sinkitty 200 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067818574",
            "code": "15139",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Sinkitty 25 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "7330129003525",
            "code": "OK4825V",
            "text": "Hitsauspuikko Esab OK 48.00/2.5mm 1,7kg VacPac",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "8712067821000",
            "code": "KAS240",
            "text": "Kaasusuutin Pitstone ja muille Mig-polttimille 24 KD (250A)",
            "info": "Pituus 63,5 mm, halkaisija 16,2 mm, josta suutin kiinnitetään polttimen kaulalle",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusuutin"
        },
        {
            "ean_code": "8712067820140",
            "code": "32101",
            "text": "Vetoniitti, Alumiini/Teräs Kupukanta 3,2 x10 mm/50 kpl",
            "info": "Vetoniitti, Alumiini/Teräs Kupukanta 3,2 x10 mm/50 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820157",
            "code": "32102",
            "text": "Vetoniitti, Ruostumaton Kupukanta 3,2 x10 mm/50 kpl",
            "info": "Vetoniitti, Ruostumaton Kupukanta 3,2 x10 mm/50 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820188",
            "code": "32801",
            "text": "Vetoniitti, Alumiini/Teräs Kupukanta 3,2 x 8 mm/50 kpl",
            "info": "Vetoniitti, Alumiini/Teräs Kupukanta 3,2 x 8 mm/50 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820171",
            "code": "32802",
            "text": "Vetoniitti, Alumiini/Teräs Valkoinen Kupukanta 3,2 x 8 mm/50 kpl",
            "info": "Vetoniitti, Alumiini/Teräs Valkoinen Kupukanta 3,2 x 8 mm/50 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820195",
            "code": "32803",
            "text": "Vetoniitti, Alumiini/Teräs Musta Kupukanta 3,2 x 8 mm/50 kpl",
            "info": "Vetoniitti, Alumiini/Teräs Musta Kupukanta 3,2 x 8 mm/50 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820027",
            "code": "35131",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Valkoinen/RR20 200 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820010",
            "code": "35132",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Musta/RR33 200 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820041",
            "code": "35133",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta T.harmaa/RR23 200 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820003",
            "code": "35134",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Punainen/RR29 200 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820034",
            "code": "35135",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Ruskea/RR31 200 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820447",
            "code": "35136",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Valkoinen/RR20 25 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820454",
            "code": "35137",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta Musta/RR33 25 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820461",
            "code": "35138",
            "text": "Peltiruuvi porakärjellä M 3,5 x13 mm Torx 15 kanta T.harmaa/RR23 25 kpl",
            "info": "Käyttökohteina mm. ikkunapellitykset sekä rännien ja vesikourujen kiinnitykset.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Peltiruuvi"
        },
        {
            "ean_code": "8712067820164",
            "code": "48121",
            "text": "Vetoniitti, Ruostumaton Kupukanta 4,8 x12 mm/50 kpl",
            "info": "Vetoniitti, Ruostumaton Kupukanta 4,8 x12 mm/50 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067815276",
            "code": "48210",
            "text": "Vetoniitti, Haara-/ruusuniitti Alumiini/Teräs 4,8 x 21 mm/15 kpl",
            "info": "Vetoniitti, Haara-/ruusuniitti Alumiini/Teräs 4,8 x 21 mm/15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820249",
            "code": "48211",
            "text": "Vetoniitti, Teräs Laajakanta 14 mm 4,8 x 21 mm/15 kpl",
            "info": "Vetoniitti, Teräs Laajakanta 14 mm 4,8 x 21 mm/15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820379",
            "code": "48212",
            "text": "Vetoniitti, Teräs Musta Laajakanta 14 mm 4,8 x 18 mm/15 kpl",
            "info": "Vetoniitti, Teräs Musta Laajakanta 14 mm 4,8 x 18 mm/15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820218",
            "code": "48213",
            "text": "Vetoniitti, Alumiini/Teräs Laajakanta 16 mm 4,8 x 21 mm/15 kpl",
            "info": "Vetoniitti, Alumiini/Teräs Laajakanta 16 mm 4,8 x 21 mm/15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820256",
            "code": "48214",
            "text": "Vetoniitti, Alumiini/Ruostumaton Eloksoitu Musta 4,8 x 21 mm/15 kpl",
            "info": "Vetoniitti, Alumiini/Ruostumaton Eloksoitu Musta 4,8 x 21 mm/15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8712067820201",
            "code": "48351",
            "text": "Vetoniitti, Teräs Kupukanta 4,8 x 35 mm/15 kpl",
            "info": "Vetoniitti, Teräs Kupukanta 4,8 x 35 mm/15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vetoniitti"
        },
        {
            "ean_code": "8024499115375",
            "code": "461620",
            "text": "Hitsauspuikko Muuntaja/tasasuuntaaja 1,6 mm/20 kpl",
            "info": "Hitsauspuikko on erinomainen rutiilipuikko yleiskäyttöön. Sileät ja hyvänmuotoiset hitsit kaikissa hitsausasennoissa. Varma syttyminen ja uudelleensyttyminen,mikä tekee sen ihanteelliseksi pohjapalkojen hitsaukseen, lyhyille hitseille ja silloituksiin. Kuona on helppo poistaa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Muuntaja/tasasuuntaaja"
        },
        {
            "ean_code": "8024499115542",
            "code": "462025",
            "text": "Hitsauspuikko Muuntaja/tasasuuntaaja 2,0 mm/18 kpl",
            "info": "Hitsauspuikko on erinomainen rutiilipuikko yleiskäyttöön. Sileät ja hyvänmuotoiset hitsit kaikissa hitsausasennoissa. Varma syttyminen ja uudelleensyttyminen,mikä tekee sen ihanteelliseksi pohjapalkojen hitsaukseen, lyhyille hitseille ja silloituksiin. Kuona on helppo poistaa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Muuntaja/tasasuuntaaja"
        },
        {
            "ean_code": "8024499115382",
            "code": "462514",
            "text": "Hitsauspuikko Muuntaja/tasasuuntaaja 2,5 mm/12 kpl",
            "info": "Hitsauspuikko on erinomainen rutiilipuikko yleiskäyttöön. Sileät ja hyvänmuotoiset hitsit kaikissa hitsausasennoissa. Varma syttyminen ja uudelleensyttyminen,mikä tekee sen ihanteelliseksi pohjapalkojen hitsaukseen, lyhyille hitseille ja silloituksiin. Kuona on helppo poistaa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Muuntaja/tasasuuntaaja"
        },
        {
            "ean_code": "8024499115405",
            "code": "463209",
            "text": "Hitsauspuikko Muuntaja/tasasuuntaaja 3,2 mm/8 kpl",
            "info": "Hitsauspuikko on erinomainen rutiilipuikko yleiskäyttöön. Sileät ja hyvänmuotoiset hitsit kaikissa hitsausasennoissa. Varma syttyminen ja uudelleensyttyminen,mikä tekee sen ihanteelliseksi pohjapalkojen hitsaukseen, lyhyille hitseille ja silloituksiin. Kuona on helppo poistaa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Muuntaja/tasasuuntaaja"
        },
        {
            "ean_code": "8712067810127",
            "code": "ALH255",
            "text": "Hitsauspuikko Alumiinille AlSi 12 2,5 mm/5 kpl",
            "info": "Alumiinipuikko erikoisesti valssattujen ja valualumiinien hitsaukseen, joissa Si>7%. Soveltuu myös pinnoitukseen. Hyvä hitsattavuus, ei huokosia. Soveltuu alumiinilaaduille, joita ei tunneta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Alumiinille"
        },
        {
            "ean_code": "8712067810219",
            "code": "ALH325",
            "text": "Hitsauspuikko Alumiinille AlSi 12 3,2 mm/5 kpl",
            "info": "Alumiinipuikko erikoisesti valssattujen ja valualumiinien hitsaukseen, joissa Si>7%. Soveltuu myös pinnoitukseen. Hyvä hitsattavuus, ei huokosia. Soveltuu alumiinilaaduille, joita ei tunneta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Alumiinille"
        },
        {
            "ean_code": "8712067810660",
            "code": "ALM105",
            "text": "Mig-lanka Alumiinille 1,0mm/0,5kg (d 200 mm)",
            "info": "AL Mg 5 Mn on MIG-alumiinilanka hitsaukseen, korjaukseen ja pinnoitukseen alumiini-mangaani-takeille ja -valuille sekä alumiini-magnesiumseoksille maksimiin 5% Mg-pitoisuuteen asti. Soveltuvia perusmateriaaleja: Al-Mn seokset, Al-Mg seokset, Al-MgSi seokset. Ns.merivesilaatu.",
            "main_category": "2. Mig-langat",
            "down_category": "Mig-lanka Alumiinille"
        },
        {
            "ean_code": "8056300371922",
            "code": "ARGHI",
            "text": "Kertakäyttö suojakaasu Argon/hiilidioksidi",
            "info": "Kertakäyttöinen suojakaasupullo normaalien terästen hitsaukseen. Pullon ulkokierre M10 x 1,00 mm.",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Suojakaasu"
        },
        {
            "ean_code": "8056300371915",
            "code": "ARGON",
            "text": "Kertakäyttö suojakaasu Argon",
            "info": "Kertakäyttöinen suojakaasupullo mm. ruostumattoman teräksen ja alumiinin hitsaukseen. Pullon ulkokierre M10 x 1,00 mm.",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Suojakaasu"
        },
        {
            "ean_code": "8712067820553",
            "code": "FOK225",
            "text": "Fosforikupari 2x2 mm L-AG 2% /5 kpl",
            "info": "Fosforikuparia käytetään juotosliitoksiin esim. liittämään kuparisia osia toisiinsa. Paksuus 2x2 mm,  sisältää 2% hopeaa. Työlämpötila 740 °C. l = 500 mm/kpl",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Fosforikupari"
        },
        {
            "ean_code": "7330725031038",
            "code": "HIMANO",
            "text": "Esab, Hitsausmaski normaali luukulla 60 x 110 mm",
            "info": "Jämäkkä, kevyt ja ergonomisesti muotoiltu hitsausmaski",
            "main_category": "5. Hitsausmaskit ja -lasit",
            "down_category": "Hitsausmaski"
        },
        {
            "ean_code": "7330725066313",
            "code": "HITWAR",
            "text": "Esab automaattihitsausmaski säädöllä din 9-13",
            "info": "Monipuolisilla säädöillä varustettu laadukas ergonomisesti muotoiltu automaattihitsausmaski",
            "main_category": "5. Hitsausmaskit ja -lasit",
            "down_category": "Hitsausmaski"
        },
        {
            "ean_code": "8712067820072",
            "code": "HOP151",
            "text": "Hopeakovajuote Juoksutepäällysteellä L-Ag 45 Sn /1 kpl",
            "info": "Hopeajuote erilaisten metallien liittämiseen lämpötila-alueella 600 – 800 °C, kupari/messinki/rauta/ruostumaton- ja haponkestävä teräs, myös sekaliitoksiin. Juotostulos on erittäin kestävä ja tiivis liitos.  Juoksutepäällysteinen juote ei tarvitse erillistä juoksutetta. l = 500 mm/kpl",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hopeakovajuote"
        },
        {
            "ean_code": "8712067820560",
            "code": "HOP152",
            "text": "Hopeakovajuote Juoksutepäällysteellä L-Ag 45 Sn /2 kpl",
            "info": "Hopeajuote erilaisten metallien liittämiseen lämpötila-alueella 600 – 800 °C, kupari/messinki/rauta/ruostumaton- ja haponkestävä teräs, myös sekaliitoksiin. Juotostulos on erittäin kestävä ja tiivis liitos.  Juoksutepäällysteinen juote ei tarvitse erillistä juoksutetta. l = 500 mm/kpl",
            "main_category": "2. Hitsauspuikot ja -juotteet",
            "down_category": "Hopeakovajuote"
        },
        {
            "ean_code": "8712067820430",
            "code": "KAITEL",
            "text": "Kaindl porakoneen kiinnitysteline",
            "info": "Telineeseen on helppo kiinnittää porakone. Pallonivelen ansiosta porakone voidaan asettaa haluttuun asentoon työstämistä varten",
            "main_category": "9. Kaindl Terät ja porakoneen kiinnitysteline",
            "down_category": "Porakoneen kiinnitysteline"
        },
        {
            "ean_code": "8712067820225",
            "code": "KAK091",
            "text": "Mig-lanka Kuparoitu Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,9 mm /1,0 kg (kela Ø 100/17 mm)",
            "info": "Kaasuton kuparoitu täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille. Saumattoman putkirakenteen ansiosta erinomainen hitsattavuus. Pienen kitkan ansiosta kuluvien osion huoltotarve on pieni ja kuparoidun pinnan ansioista erinomainen sähkönjohtavuus.",
            "main_category": "2. Mig-langat",
            "down_category": "Kuparoitu/kaasuton täytelanka"
        },
        {
            "ean_code": "8712067820355",
            "code": "KAK901",
            "text": "Mig-lanka Kuparoitu Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,9 mm /1,0 kg (kela Ø 200/50 mm)",
            "info": "Kaasuton kuparoitu täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille. Saumattoman putkirakenteen ansiosta erinomainen hitsattavuus. Pienen kitkan ansiosta kuluvien osion huoltotarve on pieni ja kuparoidun pinnan ansioista erinomainen sähkönjohtavuus.",
            "main_category": "2. Mig-langat",
            "down_category": "Kuparoitu/kaasuton täytelanka"
        },
        {
            "ean_code": "8712067812008",
            "code": "KAL201",
            "text": "Kaasuhitsauslanka 2,0 mm/10 kpl",
            "info": "Kaasuhitsauslanka normaalien/seostamattomien terästen kaasuhitsaukseen. l = 500 mm/kpl",
            "main_category": "3. Tig- ja kaasuhitsauslangat",
            "down_category": "Kaasuhitsauslanka"
        },
        {
            "ean_code": "8712067812138",
            "code": "KAL251",
            "text": "Kaasuhitsauslanka 2,5 mm/10 kpl",
            "info": "Kaasuhitsauslanka normaalien/seostamattomien terästen kaasuhitsaukseen. l = 500 mm/kpl",
            "main_category": "3. Tig- ja kaasuhitsauslangat",
            "down_category": "Kaasuhitsauslanka"
        },
        {
            "ean_code": "761445025859",
            "code": "KALS5",
            "text": "Kaasuhitsauslasi Din 5,0",
            "info": "Kevyt sangallinen ergonomisesti muotoiltu kaasuhitsaulaski Din 5,0.",
            "main_category": "5. Hitsausmaskit ja -lasit",
            "down_category": "Kaasuhitsauslasi"
        },
        {
            "ean_code": "6420172225010",
            "code": "KAM120",
            "text": "Kaindl metallin/pellin katkaisuterä 120mm x 22,2mm",
            "info": "Erinomainen pellin leikkaukseen ja pehmeiden metalliprofiilien katkaisuun.",
            "main_category": "9. Kaindl Terät ja porakoneen kiinnitysteline",
            "down_category": "Katkaisuterä"
        },
        {
            "ean_code": "8712067820317",
            "code": "KAM708",
            "text": "Mig-lanka Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,7mm /0,8 kg (kela Ø 100/17 mm)",
            "info": "Kaasuton täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille.",
            "main_category": "2. Mig-langat",
            "down_category": "Mig-lanka kaasuttomaan hitsaukseen"
        },
        {
            "ean_code": "8712067820324",
            "code": "KAM710",
            "text": "Mig-lanka Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,7mm /1,0 kg (kela Ø 200/50 mm)",
            "info": "Kaasuton täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille.",
            "main_category": "3. Mig-langat",
            "down_category": "Mig-lanka kaasuttomaan hitsaukseen"
        },
        {
            "ean_code": "8024499260402",
            "code": "KAM909",
            "text": "Mig-lanka Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,9mm/0,9kg (kela Ø 100/17mm)",
            "info": "Kaasuton täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille.",
            "main_category": "4. Mig-langat",
            "down_category": "Mig-lanka kaasuttomaan hitsaukseen"
        },
        {
            "ean_code": "8024499260679",
            "code": "KAM915",
            "text": "Mig-lanka Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,9mm/1,5kg (kela Ø 200/50mm)",
            "info": "Kaasuton täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille.",
            "main_category": "5. Mig-langat",
            "down_category": "Mig-lanka kaasuttomaan hitsaukseen"
        },
        {
            "ean_code": "8024499260389",
            "code": "KAM945",
            "text": "Mig-lanka Kaasuttomaan Hitsaukseen (ei tarvitse suojakaasua) Ø 0,9mm/0,45kg (kela Ø 100/17mm)",
            "info": "Kaasuton täytelanka \"mustille\"teräksille: ohuet sähkösinkityt ja seostamattomat teräkset. Lanka hitsataan miinus (-) navalla. Suositellaan yksipalkohitsauksiin ja soveltuu erinomaisesti pienille valovirtatoimisille kannettaville MIG/MAG-laitteille.",
            "main_category": "6. Mig-langat",
            "down_category": "Mig-lanka kaasuttomaan hitsaukseen"
        },
        {
            "ean_code": "8712067813784",
            "code": "KAMT25",
            "text": "Kaasusuutin Kemppi Mig-polttimille MT 25 (150-250A)",
            "info": "Kaasusuutin Kemppi Mig-polttimille MT 25 (150-250A)",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusuutin"
        },
        {
            "ean_code": "8712067812718",
            "code": "KAN102",
            "text": "Kaapeliliitin, Naaras 10 - 25 mm2",
            "info": "Kaapeliliitin 10-25 mm2 kaapelien liittämiseen",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaapeliliitin"
        },
        {
            "ean_code": "8712067814750",
            "code": "KAN355",
            "text": "Kaapeliliitin, Naaras 35 - 50 mm2",
            "info": "Kaapeliliitin 35-50 mm2 kaapelien liittämiseen",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaapeliliitin"
        },
        {
            "ean_code": "4003103231520",
            "code": "KAP115",
            "text": "Kaindl Puuntyöstölaikka kulmahiomakoneeseen 115mm",
            "info": "Erittäin tehokas puuntyöstölaikka kulmahiomakoneeseen puun työstämiseen, muovaamiseen ja muotoiluun",
            "main_category": "9. Kaindl Terät ja porakoneen kiinnitysteline",
            "down_category": "Puuntyöstölaikka"
        },
        {
            "ean_code": "8712067820614",
            "code": "KAS100",
            "text": "Kaasusytytin kuppimalli, 1-kivinen",
            "info": "Kaasusytytin kuppimalli, 1-kivinen",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusytytin"
        },
        {
            "ean_code": "8712067820508",
            "code": "KAS150",
            "text": "Kaasusuutin Mig-polttimelle 130 - 150 A",
            "info": "Pituus 54,2 mm, halkaisija 12,0 mm, josta suutin kiinnitetään polttimen kaulalle",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusuutin"
        },
        {
            "ean_code": "8712067820515",
            "code": "KAS250",
            "text": "Kaasusuutin Mig-polttimelle 250 A",
            "info": "Pituus 57,5 mm, halkaisija 15,0 mm, josta suutin kiinnitetään polttimen kaulalle",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusuutin"
        },
        {
            "ean_code": "8712067811452",
            "code": "KAS300",
            "text": "Kaasusytytin kuppimalli, 3-kivinen",
            "info": "Kaasusytytin kuppimalli, 3-kivinen",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusytytin"
        },
        {
            "ean_code": "8712067820522",
            "code": "KAS360",
            "text": "Kaasusuutin Mig-polttimelle 360 A",
            "info": "Pituus 84,0 mm, halkaisija 19,5 mm, josta suutin kiinnitetään polttimen kaulalle",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusuutin"
        },
        {
            "ean_code": "4003103573712",
            "code": "KAT110",
            "text": "Kaindl timanttiteroituslaikka kulmahiomakoneeseen 110mm x 22,2mm",
            "info": "Teroituslaikka kulmahiomakoneeseen terien ja työkalujen  teroittamiseen, esim. veitset, kirveet, poranterät",
            "main_category": "9. Kaindl Terät ja porakoneen kiinnitysteline",
            "down_category": "Timanttiteroituslaikka"
        },
        {
            "ean_code": "4003103573705",
            "code": "KAT116",
            "text": "Kaindl timanttiteroituslaikka porakoneeseen 6mm karalla",
            "info": "Teroituslaikka porakoneeseen terien ja työkalujen  teroittamiseen, esim. veitset, kirveet, poranterät",
            "main_category": "9. Kaindl Terät ja porakoneen kiinnitysteline",
            "down_category": "Timanttiteroituslaikka"
        },
        {
            "ean_code": "8712067817898",
            "code": "KAT800",
            "text": "Katiska/Verkkosinkilä kuumasinkitty 3/8\" ~ 800 g ~ 1200 kpl",
            "info": "Erilaisten verkkojen kiinnittämiseen mm. puutarhojen aitaverkot ja katiskojen valmistus",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Katiska/Verkkosinkilä"
        },
        {
            "ean_code": "8712067820423",
            "code": "KATSIN",
            "text": "Katiska/Verkkosinkilä kuumasinkitty 3/8\" ~ 300 g ~ 450 kpl",
            "info": "Erilaisten verkkojen kiinnittämiseen mm. puutarhojen aitaverkot ja katiskojen valmistus",
            "main_category": "9. Kiinnitystarvikkeet",
            "down_category": "Katiska/Verkkosinkilä"
        },
        {
            "ean_code": "8712067817478",
            "code": "KAU102",
            "text": "Kaapeliliitin, Uros 10 - 25 mm2",
            "info": "Kaapeliliitin 10-25 mm2 kaapelien liittämiseen",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaapeliliitin"
        },
        {
            "ean_code": "8712067817348",
            "code": "KAU355",
            "text": "Kaapeliliitin, Uros 35 - 50 mm2",
            "info": "Kaapeliliitin 35-50 mm2 kaapelien liittämiseen",
            "main_category": "5. Hitsaustarvikkeet",
            "down_category": "Kaapeliliitin"
        },
        {
            "ean_code": "8712067820621",
            "code": "KAV100",
            "text": "Kaasusytyttimen varakivi, 1-kivinen 5 kpl",
            "info": "Kaasusytyttimen varakivi, 1-kivinen 5 kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusytyttimen varakivi"
        },
        {
            "ean_code": "8712067811582",
            "code": "KAV300",
            "text": "Kaasusytyttimen varakivi, 3-kivinen 2 kpl",
            "info": "Kaasusytyttimen varakivi, 3-kivinen 2 kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kaasusytyttimen varakivi"
        },
        {
            "ean_code": "8712067813302",
            "code": "KAVMKP",
            "text": "Kaasuvirtausmittari Kertakäyttöisille Suojakaasupulloille",
            "info": "Mittarissa on kierrettävä säätönuppi, josta kaasun virtausta voi säätää. Mittarin sisäkierre M10 x 1,00 mm.",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Kaasuvirtausmittari"
        },
        {
            "ean_code": "5051388257796",
            "code": "KMACE",
            "text": "Kaasuvirtausmittari Asetyleeni 2-kellolla",
            "info": "Laadukas teollisuuskäyttöön tarkoitettu virtaussäädin asetyleeni-kaasulle. Virtaussäädin sisältää nailontiivisteen, letkukaran ja kiinnitysmutterit. Pulloliitäntä: G3/4\" (ulkokierre, vasen). Ulostuloliitäntä: G3/8\" (ulkokierre, oikea).",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Kaasuvirtausmittari"
        },
        {
            "ean_code": "5051388257772",
            "code": "KMARG",
            "text": "Kaasuvirtausmittari Argon 2-kellolla",
            "info": "Laadukas teollisuuskäyttöön tarkoitettu virtaussäädin MIG/MAG- ja TIG-hitsaukseen argon ja seoskaasuilla. Virtaussäädin sisältää nailontiivisteen, letkukaran ja kiinnitysmutterit. Pulloliitäntä: 24,32 x 1,814 (sisäkierre, oikea). Ulostuloliitäntä: G3/8\" (ulkokierre, oikea).",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Kaasuvirtausmittari"
        },
        {
            "ean_code": "5051388257789",
            "code": "KMHAP",
            "text": "Kaasuvirtausmittari Happi 2-kellolla",
            "info": "Laadukas teollisuuskäyttöön tarkoitettu virtaussäädin happi-kaasulle. Virtaussäädin sisältää nailontiivisteen, letkukaran ja kiinnitysmutterit. Pulloliitäntä: 21,8 x 1,814 (sisäkierre, oikea). Ulostuloliitäntä: G3/8\" (ulkokierre, oikea).",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Kaasuvirtausmittari"
        },
        {
            "ean_code": "8712067820232",
            "code": "KOH255",
            "text": "Hitsauspuikko Korjaushitsaukseen 2,5 mm/5 kpl",
            "info": "Rutiili-emäksinen voimakkaasti CrNi-seostettu puikko kaikkiin asentoihin. Erinomainen korjaushitsauksiin. Erikoisesti kehitetty vaikeasti hitsattaville teräksille, kuten: panssarilevyille, austeniittisille Mn-teräksille ja korkeahiilisille teräksille. Erinomainen hitsattavuus ja itsestään irtoava kuona. Hitsattavissa AC:lla ja DC+ navalla.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Korjaushitsaukseen"
        },
        {
            "ean_code": "8712067814590",
            "code": "KOV255",
            "text": "Hitsauspuikko Kovahitsaukseen Kovuus 45-58 HRc 2,5 mm/5 kpl",
            "info": "Kovahitsauspuikko, jonka kromiseosteisella hitsiaineella on suuri kovuus, kohtalainen syöpymiskestävyys ja hilseilynkestävyys. Hitsiaineen kovuus on 45-58 HRc, mikä saavutetaan jo ensimmäisessä palossa. Se sopii hyvin yleiskovahitsauspuikoksi esim. kuormaus- ja tiekoneiden osien, sekoittajien ja syöttöruuvien kovahitsaukseen.",
            "main_category": "2. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Kovahitsaukseen"
        },
        {
            "ean_code": "8712067814620",
            "code": "KOV325",
            "text": "Hitsauspuikko Kovahitsaukseen Kovuus 45-58 HRc 3,2 mm/5 kpl",
            "info": "Kovahitsauspuikko, jonka kromiseosteisella hitsiaineella on suuri kovuus, kohtalainen syöpymiskestävyys ja hilseilynkestävyys. Hitsiaineen kovuus on 45-58 HRc, mikä saavutetaan jo ensimmäisessä palossa. Se sopii hyvin yleiskovahitsauspuikoksi esim. kuormaus- ja tiekoneiden osien, sekoittajien ja syöttöruuvien kovahitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Kovahitsaukseen"
        },
        {
            "ean_code": "8712067820416",
            "code": "KUH612",
            "text": "Kuusiokolopultti M6 x12 mm haponkestävä DIN 912 /30 kpl",
            "info": "Kuusiokolopultti M6 x12 mm haponkestävä DIN 912 /30 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820089",
            "code": "KUH620",
            "text": "Kuusiokolopultti M6 x 20 mm haponkestävä DIN 912 /30 kpl",
            "info": "Kuusiokolopultti M6 x 20 mm haponkestävä DIN 912 /30 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820096",
            "code": "KUH630",
            "text": "Kuusiokolopultti M6 x 30 mm haponkestävä DIN 912 /30 kpl",
            "info": "Kuusiokolopultti M6 x 30 mm haponkestävä DIN 912 /30 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820102",
            "code": "KUH640",
            "text": "Kuusiokolopultti M6 x 40 mm haponkestävä DIN 912 /20 kpl",
            "info": "Kuusiokolopultti M6 x 40 mm haponkestävä DIN 912 /20 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820119",
            "code": "KUH820",
            "text": "Kuusiokolopultti M8 x 20 mm haponkestävä DIN 912 /20 kpl",
            "info": "Kuusiokolopultti M8 x 20 mm haponkestävä DIN 912 /20 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820126",
            "code": "KUH830",
            "text": "Kuusiokolopultti M8 x 30 mm haponkestävä DIN 912 /15 kpl",
            "info": "Kuusiokolopultti M8 x 30 mm haponkestävä DIN 912 /15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820133",
            "code": "KUH840",
            "text": "Kuusiokolopultti M8 x 40 mm haponkestävä DIN 912 /15 kpl",
            "info": "Kuusiokolopultti M8 x 40 mm haponkestävä DIN 912 /15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067814880",
            "code": "KUNM10",
            "text": "Kulmanivel lukolla + mutteri M10",
            "info": "Kulmanivel lukolla + mutteri M10",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kulmanivel"
        },
        {
            "ean_code": "8712067815146",
            "code": "KUNM5",
            "text": "Kulmanivel lukolla + mutteri M5",
            "info": "Kulmanivel lukolla + mutteri M5",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kulmanivel"
        },
        {
            "ean_code": "8712067815016",
            "code": "KUNM6",
            "text": "Kulmanivel lukolla + mutteri M6",
            "info": "Kulmanivel lukolla + mutteri M6",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kulmanivel"
        },
        {
            "ean_code": "8712067814910",
            "code": "KUNM8",
            "text": "Kulmanivel lukolla + mutteri M8",
            "info": "Kulmanivel lukolla + mutteri M8",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kulmanivel"
        },
        {
            "ean_code": "8712067811612",
            "code": "KUOVAS",
            "text": "Kuonavasara terästä, taltta/piikkipää",
            "info": "Kuonavasara terästä, taltta/piikkipää",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Kuonavasara"
        },
        {
            "ean_code": "8712067820409",
            "code": "KUS612",
            "text": "Kuusiokolopultti M6 x12 mm sinkitty DIN 912 /30 kpl",
            "info": "Kuusiokolopultti M6 x12 mm sinkitty DIN 912 /30 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820263",
            "code": "KUS620",
            "text": "Kuusiokolopultti M6 x 20 mm sinkitty DIN 912 /30 kpl",
            "info": "Kuusiokolopultti M6 x 20 mm sinkitty DIN 912 /30 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820270",
            "code": "KUS630",
            "text": "Kuusiokolopultti M6 x 30 mm sinkitty DIN 912 /30 kpl",
            "info": "Kuusiokolopultti M6 x 30 mm sinkitty DIN 912 /30 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820287",
            "code": "KUS640",
            "text": "Kuusiokolopultti M6 x 40 mm sinkitty DIN 912 /20 kpl",
            "info": "Kuusiokolopultti M6 x 40 mm sinkitty DIN 912 /20 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820386",
            "code": "KUS820",
            "text": "Kuusiokolopultti M8 x 20 mm sinkitty DIN 912 /20 kpl",
            "info": "Kuusiokolopultti M8 x 20 mm sinkitty DIN 912 /20 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820393",
            "code": "KUS830",
            "text": "Kuusiokolopultti M8 x 30 mm sinkitty DIN 912 /15 kpl",
            "info": "Kuusiokolopultti M8 x 30 mm sinkitty DIN 912 /15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820362",
            "code": "KUS840",
            "text": "Kuusiokolopultti M8 x 40 mm sinkitty DIN 912 /15 kpl",
            "info": "Kuusiokolopultti M8 x 40 mm sinkitty DIN 912 /15 kpl",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kuusiokolopultti"
        },
        {
            "ean_code": "8712067820676",
            "code": "MAA200",
            "text": "Maadoituspuristin kuparijohtimilla 200 A",
            "info": "Tukeva voimakaspuristeinen kuparijohtimilla varustettu yleismallinen maadoituspuristin",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Maadoituspuristin"
        },
        {
            "ean_code": "8712067820683",
            "code": "MAA400",
            "text": "Maadoituspuristin kuparijohtimilla 400 A",
            "info": "Tukeva voimakaspuristeinen kuparijohtimilla varustettu yleismallinen maadoituspuristin",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Maadoituspuristin"
        },
        {
            "ean_code": "8712067811742",
            "code": "MAM200",
            "text": "Maadoituspuristin Messinki 200 A",
            "info": "Tukeva voimakaspuristeinen täysmessinkinen maadoituspuristin",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Maadoituspuristin"
        },
        {
            "ean_code": "8712067811902",
            "code": "MAM600",
            "text": "Maadoituspuristin Messinki 600 A",
            "info": "Tukeva voimakaspuristeinen täysmessinkinen maadoituspuristin",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Maadoituspuristin"
        },
        {
            "ean_code": "8712067812978",
            "code": "MAR600",
            "text": "Maadoituspuristin, Ruuvattava 600 A",
            "info": "Jykevä teollisuuskäyttöön tarkoitettu ruuvattava maadoituspuristin",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Maadoituspuristin"
        },
        {
            "ean_code": "8712067813074",
            "code": "MAR700",
            "text": "Maadoituspuristin, Ruuvattava 700 A",
            "info": "Jykevä teollisuuskäyttöön tarkoitettu ruuvattava maadoituspuristin",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Maadoituspuristin"
        },
        {
            "ean_code": "8712067820546",
            "code": "MEJ205",
            "text": "Messinkijuote Juoksutepäällysteellä B-Cu 60 Zn(Si)(Mn) 2,0 mm/5 kpl",
            "info": "Soveltuu kuparin, nikkelin ja teräksen juottamiseen sekä messingin ja pronssin hitsaamiseen. Työskentelylämpötila n. 900 °C. Juoksutepäällysteinen juote ei tarvitse erillistä juoksutetta. l = 500 mm/kpl",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Messinkijuote"
        },
        {
            "ean_code": "8712067820348",
            "code": "MER500",
            "text": "Merkkausliidun pidin + 5 kpl liituja",
            "info": "Merkkausliidun pidin + 5 kpl liituja",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Merkkausliidin pidin ja liidut"
        },
        {
            "ean_code": "8712067820980",
            "code": "MER600",
            "text": "Merkkausliitu \"Tällä\" 127x13x5mm 6 kpl",
            "info": "Merkkausliitu \"Tällä\" 127x13x5mm 6 kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Merkkausliitu"
        },
        {
            "ean_code": "8712067813364",
            "code": "MIG150",
            "text": "Mig-poltin, Titanium 150 A pallonivelöity, euroliittimellä",
            "info": "Mig-poltin ammattikäyttöön, molemmissa päissä pallonivelet helpottamaan polttimen käyttöä.",
            "main_category": "6. Mig-polttimet",
            "down_category": "Mig-poltin"
        },
        {
            "ean_code": "8712067813494",
            "code": "MIG250",
            "text": "Mig-poltin, Titanium 250 A pallonivelöity, euroliittimellä",
            "info": "Mig-poltin ammattikäyttöön, molemmissa päissä pallonivelet helpottamaan polttimen käyttöä.",
            "main_category": "6. Mig-polttimet",
            "down_category": "Mig-poltin"
        },
        {
            "ean_code": "8712067814330",
            "code": "MIG360",
            "text": "Mig-poltin, Titanium 360 A/4 m pallonivelöity, euroliittimellä",
            "info": "Mig-poltin ammattikäyttöön, molemmissa päissä pallonivelet helpottamaan polttimen käyttöä.",
            "main_category": "6. Mig-polttimet",
            "down_category": "Mig-poltin"
        },
        {
            "ean_code": "8712067820065",
            "code": "NEUSRJ",
            "text": "Neulaviilasarja 13-osainen virtasuuttimille ja muille rei'ille",
            "info": "Neulaviilasarja 13-osainen virtasuuttimille ja muille rei'ille",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Neulaviilasarja"
        },
        {
            "ean_code": "7330129003365",
            "code": "OK4816",
            "text": "Hitsauspuikko Esab OK 48.00/1,6 mm 1,6 kg",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "7330129003389",
            "code": "OK4820",
            "text": "Hitsauspuikko Esab OK 48.00/2,0 mm 1,7 kg",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },

        {
            "ean_code": "7330129121373",
            "code": "OK4820V",
            "text": "Hitsauspuikko Esab OK 48.00/2.0mm 0,6kg VacPac",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },

        {
            "ean_code": "7330129003464",
            "code": "OK4825",
            "text": "Hitsauspuikko Esab OK 48.00/2,5mm 4,3 kg",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "7330129003846",
            "code": "OK4832",
            "text": "Hitsauspuikko Esab OK 48.00/3,2mm 6,0 kg",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "7330129003914",
            "code": "OK4832V",
            "text": "Hitsauspuikko Esab OK 48.00/3.2mm 2,3kg VacPac",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "8712067821024",
            "code": "24825",
            "text": "Lyöntiniitti Alumiini/RST Kupukanta 4,8 x 25 mm 20 kpl",
            "info": "Erilaisten materiaalien kiinnittämiseen, esim. kivi ja betoni",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Lyöntiniitti"
        },
        {
            "ean_code": "8712067821031",
            "code": "24830",
            "text": "Lyöntiniitti Alumiini/RST Kupukanta 4,8 x 35 mm 20 kpl",
            "info": "Erilaisten materiaalien kiinnittämiseen, esim. kivi ja betoni",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Lyöntiniitti"
        },
        {
            "ean_code": "8712067821048",
            "code": "24850",
            "text": "Lyöntiniitti Alumiini/RST Kupukanta 4,8 x 50 mm 10 kpl",
            "info": "Erilaisten materiaalien kiinnittämiseen, esim. kivi ja betoni",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Lyöntiniitti"
        },
        {
            "ean_code": "8712067821055",
            "code": "27316",
            "text": "Teräsniitti Kupukanta 3 x 16 mm 20 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821062",
            "code": "27416",
            "text": "Teräsniitti Kupukanta 4 x 16 mm 10 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821079",
            "code": "27516",
            "text": "Teräsniitti Kupukanta 5 x 16 mm 6 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821086",
            "code": "27616",
            "text": "Teräsniitti Kupukanta 6 x 16 mm 30 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821093",
            "code": "27525",
            "text": "Teräsniitti Kupukanta 5 x 25 mm 5 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821109",
            "code": "27630",
            "text": "Teräsniitti Kupukanta 6 x 30 mm 20 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821116",
            "code": "27820",
            "text": "Teräsniitti Kupukanta 8 x 20 mm 15 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821123",
            "code": "27830",
            "text": "Teräsniitti Kupukanta 8 x 30 mm 10 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. terästen toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Teräsniitti"
        },
        {
            "ean_code": "8712067821130",
            "code": "25316",
            "text": "Kupariniitti Kupukanta 3 x 16 mm 20 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. kuparien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821147",
            "code": "25416",
            "text": "Kupariniitti Kupukanta 4 x 16 mm 10 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. kuparien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821154",
            "code": "25516",
            "text": "Kupariniitti Kupukanta 5 x 16 mm 6 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. kuparien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821161",
            "code": "25525",
            "text": "Kupariniitti Kupukanta 5 x 25 mm 5 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. kuparien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821178",
            "code": "26316",
            "text": "Alumiininiitti Kupukanta 3 x 16 mm 20 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. alumiinien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821185",
            "code": "26416",
            "text": "Alumiininiitti Kupukanta 4 x 16 mm 10 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. alumiinien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821192",
            "code": "26516",
            "text": "Alumiininiitti Kupukanta 5 x 16 mm 6 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. alumiinien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "8712067821208",
            "code": "26525",
            "text": "Alumiininiitti Kupukanta 5 x 25 mm 5 kpl",
            "info": "Käytetään erilaisten materiaalien niittaamiseen, mm. alumiinien toisiinsa niittaamiseen.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Kupariniitti"
        },
        {
            "ean_code": "7330129004256",
            "code": "OK4840",
            "text": "Hitsauspuikko Esab OK 48.00/4,0mm 6,2kg",
            "info": "OK 48.00 on luotettava yleiskäyttöinen erittäin vähävetyinen hitsauspuikko seostamattomille ja niukkaseosteisille teräksille. OK 48.00 on kaikkien hitsausasentojen hitsauspuikko vakaalla valokaarella ja luotettavilla mekaanisilla ominaisuuksilla. Sillä suoriudut kaikista hitsaustöistä juuren hitsauksesta hitsin pintaan saakka, ainespaksuudesta ja hitsauskohteesta riippumatta.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "7330129186334",
            "code": "OK5525",
            "text": "Hitsauspuikko Esab OK 55.00/2,5mm 4,5 kg",
            "info": "OK 55.00 on seostamaton hitsauspuikko lujemmille teräksille. Sen mangaaniseosteiselle hitsiaineelle on ominaista suuri lujuus ja erinomainen iskusitkeys matalissa lämpötiloissa ja epäherkkyys kuumahalkeamille. OK 55.00 soveltuu seostamattomien rakenneterästen ja hienoraeterästen hitsaukseen, kun tarvitaan suurempaa, lujempaa ja parempaa iskusitkeyttä hitsiaineessa kuin mitä yleispuikolla OK 48.00:lla saadaan.Päällysteen kostumistaipumus on pieni. Soveltuu hitsattavaksi muuntaja ja tasasuuntaaja hitsauskoneilla.",
            "main_category": "2. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "7330129186372",
            "code": "OK5532",
            "text": "Hitsauspuikko Esab OK 55.00/3,2 mm 6,2 kg",
            "info": "OK 55.00 on seostamaton hitsauspuikko lujemmille teräksille. Sen mangaaniseosteiselle hitsiaineelle on ominaista suuri lujuus ja erinomainen iskusitkeys matalissa lämpötiloissa ja epäherkkyys kuumahalkeamille. OK 55.00 soveltuu seostamattomien rakenneterästen ja hienoraeterästen hitsaukseen, kun tarvitaan suurempaa, lujempaa ja parempaa iskusitkeyttä hitsiaineessa kuin mitä yleispuikolla OK 48.00:lla saadaan.Päällysteen kostumistaipumus on pieni. Soveltuu hitsattavaksi muuntaja ja tasasuuntaaja hitsauskoneilla.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Esab"
        },
        {
            "ean_code": "8712067813524",
            "code": "OP300",
            "text": "Puikonpidin OP- 300 A",
            "info": "Erinomainen hyvin muotoiltu puikonpidin OP- 300 A ammattikäyttöön",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Puikopidin"
        },
        {
            "ean_code": "8712067811322",
            "code": "OP400",
            "text": "Puikonpidin OP- 400 A",
            "info": "Erinomainen hyvin muotoiltu puikonpidin OP- 400 A ammattikäyttöön",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Puikopidin"
        },
        {
            "ean_code": "8712067820966",
            "code": "PUI200",
            "text": "Puikonpidin 200/300 A",
            "info": "Hyvä puikonpidin 200/300 A yleiskäyttöön",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Puikopidin"
        },
        {
            "ean_code": "8712067810202",
            "code": "RSH155",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävälle 1,5 mm/5 kpl",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067820898",
            "code": "RSH200",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävälle 2,0 mm/500 g",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067810196",
            "code": "RSH205",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävälle 2,0 mm/5 kpl",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067820904",
            "code": "RSH250",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävävälle 2,5 mm/500g",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067810189",
            "code": "RSH255",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävälle 2,5 mm/5 kpl",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067820911",
            "code": "RSH320",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävävälle 3,2 mm/500g",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067820713",
            "code": "RSH325",
            "text": "Hitsauspuikko Ruostumattomalle/Haponkestävälle 3,2 mm/5 kpl",
            "info": "Ruostumaton rutiilipäällysteinen puikko, joka soveltuu ruostumattomien ja haponkestävien terästen hitsaukseen kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "3. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067810165",
            "code": "RSM205",
            "text": "Hitsauspuikko Ruostumattoman/Mustanraudan Liitokseen 2,0 mm/5 kpl",
            "info": "Hapan-rutiilipäällysteinen ruostumaton, ns. haponkestävä yliseostettu, hitsauspuikko. Se on tarkoitettu ensisijassa seostamattoman tai niukkaseosteisen teräksen hitsaukseen ruostumattomaan teräkseen eli musta/ruostumaton-eripariliitosten hitsaukseen. Se soveltuu myös päällehitsauksessa ensimmäisen palkokerroksen eli puskurikerroksen hitsaukseen, kun seostamatonta tai niukkaseosteista terästä päällehitsataan ruostumattomalla lisäaineella. Hyvä hitsattavuus kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "4. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattoman/Mustanraudan Liitokseen"
        },
        {
            "ean_code": "8712067810158",
            "code": "RSM255",
            "text": "Hitsauspuikko Ruostumattoman/Mustanraudan Liitokseen 2,5 mm/5 kpl",
            "info": "Hapan-rutiilipäällysteinen ruostumaton, ns. haponkestävä yliseostettu, hitsauspuikko. Se on tarkoitettu ensisijassa seostamattoman tai niukkaseosteisen teräksen hitsaukseen ruostumattomaan teräkseen eli musta/ruostumaton-eripariliitosten hitsaukseen. Se soveltuu myös päällehitsauksessa ensimmäisen palkokerroksen eli puskurikerroksen hitsaukseen, kun seostamatonta tai niukkaseosteista terästä päällehitsataan ruostumattomalla lisäaineella. Hyvä hitsattavuus kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "5. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattoman/Mustanraudan Liitokseen"
        },
        {
            "ean_code": "8712067810141",
            "code": "RSM325",
            "text": "Hitsauspuikko Ruostumattoman/Mustanraudan Liitokseen 3,2 mm/5 kpl",
            "info": "Hapan-rutiilipäällysteinen ruostumaton, ns. haponkestävä yliseostettu, hitsauspuikko. Se on tarkoitettu ensisijassa seostamattoman tai niukkaseosteisen teräksen hitsaukseen ruostumattomaan teräkseen eli musta/ruostumaton-eripariliitosten hitsaukseen. Se soveltuu myös päällehitsauksessa ensimmäisen palkokerroksen eli puskurikerroksen hitsaukseen, kun seostamatonta tai niukkaseosteista terästä päällehitsataan ruostumattomalla lisäaineella. Hyvä hitsattavuus kaikissa asennoissa. Soveltuu tasasuuntaaja ja muuntaja hitsaukseen.",
            "main_category": "4. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Ruostumattoman/Mustanraudan Liitokseen"
        },
        {
            "ean_code": "8712067810028",
            "code": "RSM810",
            "text": "Mig-lanka Ruostumattomalle/Haponkestävälle Teräkselle 0,8mm/1,0kg (d 200mm)",
            "info": "Ruostumattoman/Haponkestävän teräksen hitsaukseen. Suojakaasuna käytetään Argonia. ",
            "main_category": "2. Mig-langat",
            "down_category": "Mig-lanka Ruostumattomalle/Haponkestävälle Teräkselle"
        },
        {
            "ean_code": "8712067812268",
            "code": "TAL241",
            "text": "Tig-hitsauslanka Alumiinille (merivedenkesto) AlMg 5 2,4 mm/10 kpl",
            "info": "Umpilanka alumiiniseosten hitsaukseen, joissa Mg >3  %. Hyvä syötettävyys ja tasainen laatu. Suppea ja vakaa kaari. Merivedenkestävä. l = 500 mm/kpl",
            "main_category": "3. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-hitsauslanka Alumiinille"
        },
        {
            "ean_code": "8712067814040",
            "code": "TIGE24",
            "text": "Tig Elektrodi \"Harmaa\" Kaikille metalleille: Alumiini, Ruostumaton/Haponk ja Norm. teräkset 2,4 mm/2 kpl ",
            "info": "Cerium-oksidin (CeO2) lisäämisen ansiosta on näiden elektrodien kestävyys parempi kuin puhtaiden wolfram-elektrodien. Näitä elektrodeja käytetään pääasiassa seostamattomien ja korkeaseosteisten terästen, alumiinin, titaanin, nikkelin, kuparin ja magnesiumseosten hitsauksessa alhaisilla sekä keskisuurilla virroilla.",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Tig Elektrodi"
        },
        {
            "ean_code": "8712067813814",
            "code": "TIGESU",
            "text": "Tig Elektrodisuojat WTC 17/18/26 \"iso palopää\" 1 x lyhyt, 1 x puolipitkä, 1 x pitkä",
            "info": "Tig Elektrodisuojat WTC 17/18/26 \"iso palopää\" 1 x lyhyt, 1 x puolipitkä, 1 x pitkä",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Tig Elektrodisuojat"
        },
        {
            "ean_code": "8712067813944",
            "code": "TIGVA3",
            "text": "Tig Varaosia WTC 17/18/26 \"iso palopää\" 2 x kaasusuutin, 1 x kiristyshylsy",
            "info": "Tig Varaosia WTC 17/18/26 \"iso palopää\" 2 x kaasusuutin, 1 x kiristyshylsy",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Tig Varaosia"
        },
        {
            "ean_code": "8712067814170",
            "code": "TIGVA5",
            "text": "Tig Varaosia WTC 17/18/26 ”Iso palopää” 1 x Lämpösuojarengas 1 x Kiristyshylsyn pesä 1 x Kiristyshylsy 1 x Kaasusuutin 1 x Elektrodisuoja puolip.",
            "info": "Tig Varaosia WTC 17/18/26 ”Iso palopää” 1 x Lämpösuojarengas 1 x Kiristyshylsyn pesä 1 x Kiristyshylsy 1 x Kaasusuutin 1 x Elektrodisuoja puolip.",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Tig Varaosia"
        },
        {
            "ean_code": "8034163808502",
            "code": "TNT201",
            "text": "Tig-Hitsauslanka Normaaleille Rakenneteräksille 2,0 mm/10 kpl",
            "info": "Ensisijainen valinta normaalien (mustien) terästen hitsaukseen. Suojakaasuna käytetään puhdasta argonia. l = 500 mm/kpl",
            "main_category": "3. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Normaaleille Rakenneteräksille"
        },
        {
            "ean_code": "8034165808524",
            "code": "TNT241",
            "text": "Tig-Hitsauslanka Normaaleille Rakenneteräksille 2,4 mm/10 kpl",
            "info": "Ensisijainen valinta normaalien (mustien) terästen hitsaukseen. Suojakaasuna käytetään puhdasta argonia. l = 500 mm/kpl",
            "main_category": "4. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Normaaleille Rakenneteräksille"
        },
        {
            "ean_code": "8712067817508",
            "code": "TRM161",
            "text": "Tig-Hitsauslanka Ruostumattomalle/Mustalleraudalle 309 Lsi 1,6 mm/10 kpl",
            "info": "Seosteinen, nk. yliseostettu ruostumaton TIG-hitsauslanka, joka on tarkoitettu ensisijaisesti seostamattoman/niukkaseosteisen ja ruostumattoman teräksen eripariliitosten hitsaukseen. l = 500 mm/kpl",
            "main_category": "5. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Ruostumattomalle/Mustalleraudalle"
        },
        {
            "ean_code": "8712067817638",
            "code": "TRM201",
            "text": "Tig-Hitsauslanka Ruostumattomalle/Mustalleraudalle 309 Lsi 2,0 mm/10 kpl",
            "info": "Seosteinen, nk. yliseostettu ruostumaton TIG-hitsauslanka, joka on tarkoitettu ensisijaisesti seostamattoman/niukkaseosteisen ja ruostumattoman teräksen eripariliitosten hitsaukseen. l = 500 mm/kpl",
            "main_category": "6. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Ruostumattomalle/Mustalleraudalle"
        },
        {
            "ean_code": "8712067817768",
            "code": "TRM241",
            "text": "Tig-Hitsauslanka Ruostumattomalle/Mustalleraudalle 309 Lsi 2,4 mm/10 kpl",
            "info": "Seosteinen, nk. yliseostettu ruostumaton TIG-hitsauslanka, joka on tarkoitettu ensisijaisesti seostamattoman/niukkaseosteisen ja ruostumattoman teräksen eripariliitosten hitsaukseen. l = 500 mm/kpl",
            "main_category": "7. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Ruostumattomalle/Mustalleraudalle"
        },
        {
            "ean_code": "8712067812398",
            "code": "TRS161",
            "text": "Tig-Hitsauslanka Ruostumattomalle/Haponkestävälle 316 Lsi 1,6 mm/10 kpl",
            "info": "Matalahiilinen umpilanka, joka on tarkoitettu ns. haponkestävien ja ruostumattomien terästen TIG-hitsaukseen. Suojakaasuna käytetään puhdasta argonia. l = 500 mm/kpl",
            "main_category": "8. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067812428",
            "code": "TRS201",
            "text": "Tig-Hitsauslanka Ruostumattomalle/Haponkestävälle 316 Lsi 2,0 mm/10 kpl",
            "info": "Matalahiilinen umpilanka, joka on tarkoitettu ns. haponkestävien ja ruostumattomien terästen TIG-hitsaukseen. Suojakaasuna käytetään puhdasta argonia. l = 500 mm/kpl",
            "main_category": "9. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067812558",
            "code": "TRS241",
            "text": "Tig-Hitsauslanka Ruostumattomalle/Haponkestävälle 316 Lsi 2,4 mm/10 kpl",
            "info": "Matalahiilinen umpilanka, joka on tarkoitettu ns. haponkestävien ja ruostumattomien terästen TIG-hitsaukseen. Suojakaasuna käytetään puhdasta argonia. l = 500 mm/kpl",
            "main_category": "10. Tig- ja kaasuhitsauslangat",
            "down_category": "Tig-Hitsauslanka Ruostumattomalle/Haponkestävälle"
        },
        {
            "ean_code": "8712067810110",
            "code": "VAH255",
            "text": "Hitsauspuikko Valuraudalle 2,5 mm/5 kpl",
            "info": "Nikkelipuikko suomugrafiittivaluraudan ja pallografiittivaluraudan korjaushitsaukseen sekä valuraudan liittämiseksi teräkseen. Tuottaa pehmeän koneistettavan hitsiaineen. Hitsin kovuus ~ 175 HB. Nikkelisydänlankainen valuraudan kylmä- ja puolikuumahitsaukseen tarkoitettu hitsauspuikko, jonka hitsiaine on lähes puhdasta nikkeliä.",
            "main_category": "1. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Valuraudalle"
        },
        {
            "ean_code": "8712067810103",
            "code": "VAH325",
            "text": "Hitsauspuikko Valuraudalle 3,2 mm/5 kpl",
            "info": "Nikkelipuikko suomugrafiittivaluraudan ja pallografiittivaluraudan korjaushitsaukseen sekä valuraudan liittämiseksi teräkseen. Tuottaa pehmeän koneistettavan hitsiaineen. Hitsin kovuus ~ 175 HB. Nikkelisydänlankainen valuraudan kylmä- ja puolikuumahitsaukseen tarkoitettu hitsauspuikko, jonka hitsiaine on lähes puhdasta nikkeliä.",
            "main_category": "2. Hitsauspuikot ja -juotteet",
            "down_category": "Hitsauspuikko Valuraudalle"
        },
        {
            "ean_code": "8712067820997",
            "code": "VIM506",
            "text": "Virtasuutin MIG-langalle M5/0,6mm 2kpl",
            "info": "Virtasuutin MIG-langalle M5/0,6mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067820881",
            "code": "VIM508",
            "text": "Virtasuutin MIG-langalle M5/0,8mm 2kpl",
            "info": "Virtasuutin MIG-langalle M5/0,8mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067813654",
            "code": "VIM509",
            "text": "Virtasuutin MIG-langalle M5/0,9mm 2kpl",
            "info": "Virtasuutin MIG-langalle M5/0,9mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067820874",
            "code": "VIM510",
            "text": "Virtasuutin MIG-langalle M5/1,0mm 2kpl",
            "info": "Virtasuutin MIG-langalle M5/1,0mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067810134",
            "code": "VIM606",
            "text": "Virtasuutin MIG-langalle M6/0,6mm 2kpl",
            "info": "Virtasuutin MIG-langalle M6/0,6mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067810097",
            "code": "VIM608",
            "text": "Virtasuutin MIG-langalle M6/0,8mm 2kpl",
            "info": "Virtasuutin MIG-langalle M6/0,8mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067812688",
            "code": "VIM609",
            "text": "Virtasuutin MIG-langalle M6/0,9mm 2kpl",
            "info": "Virtasuutin MIG-langalle M6/0,9mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067810080",
            "code": "VIM610",
            "text": "Virtasuutin MIG-langalle M6/1,0mm 2kpl",
            "info": "Virtasuutin MIG-langalle M6/1,0mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067814200",
            "code": "VIM612",
            "text": "Virtasuutin MIG-langalle M6/1,2mm 2kpl",
            "info": "Virtasuutin MIG-langalle M6/1,2mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067810073",
            "code": "VIM808",
            "text": "Virtasuutin MIG-langalle M8/0,8mm 2kpl",
            "info": "Virtasuutin MIG-langalle M8/0,8mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067810059",
            "code": "VIM810",
            "text": "Virtasuutin MIG-langalle M8/1,0mm 2kpl",
            "info": "Virtasuutin MIG-langalle M8/1,0mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "8712067810035",
            "code": "VIM812",
            "text": "Virtasuutin MIG-langalle M8/1,2mm 2kpl",
            "info": "Virtasuutin MIG-langalle M8/1,2mm 2kpl",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Virtasuutin"
        },
        {
            "ean_code": "7120678179280",
            "code": "11801",
            "text": "Vaijerilukko Kuusioruuvikiristys Reikä Ø 2,5 mm 2 kpl",
            "info": "Erilaisiin vaijerilukituksiin/kiinnityksiin mm. moottorisahoissa, ruohonleikkureissa, trimmereissä, polkupyörissä yms.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vaijerilukot"
        },
        {
            "ean_code": "7120678180248",
            "code": "11051",
            "text": "Vaijerilukko Sisäpuolisella Uraruuvilla Reikä Ø 2,7 mm 2 kpl",
            "info": "Erilaisiin vaijerilukituksiin/kiinnityksiin mm. moottorisahoissa, ruohonleikkureissa, trimmereissä, polkupyörissä yms.",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Vaijerilukot"
        },
        {
            "ean_code": "7120678183140",
            "code": "29672",
            "text": "Pistehitsiporanterä Ø  9,6/ 72 mm",
            "info": "Pistehitsien avaamiseen tarkoitettu poranterä",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Pistehitsiporanterä"
        },
        {
            "ean_code": "7102067818444",
            "code": "20296",
            "text": "Pistehitsiporan varaterä  Ø 9,6 mm 2 kpl",
            "info": "Pistehitsien avaamiseen tarkoitettu poranterän varaterä",
            "main_category": "4. Hitsaustarvikkeet",
            "down_category": "Pistehitsiporanterän varaterä"
        },
        {
            "ean_code": "7120678188640",
            "code": "14008",
            "text": "Muunnosadaptreri M14 / 8mm",
            "info": "Mahdollistaa käyttää porakoneella M14 kierteisiä tarvikkeita",
            "main_category": "8. Kiinnitystarvikkeet",
            "down_category": "Muunnosadapteri"
        },


        {
            "ean_code": "8712067819090",
            "code": "VTHAM",
            "text": "Varatiiviste Happimittarille 3 kpl",
            "info": "Varatiiviste Kaasuvirtausmittarille: Happi. Tiiviste tulisi turvallisuussuositusten mukaan vaihtaa aina, kun mittaria käytetään irti. ",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Varatiiviste"
        },
        {
            "ean_code": "8712067818994",
            "code": "VTARM",
            "text": "Varatiiviste Argonmittarille 3 kpl",
            "info": "Varatiiviste Kaasuvirtausmittarille: Argon. Tiiviste tulisi turvallisuussuositusten mukaan vaihtaa aina, kun mittaria käytetään irti. ",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Varatiiviste"
        },
        {
            "ean_code": "8712067819120",
            "code": "VTASM",
            "text": "Varatiiviste Asetyleenimittarille 3 kpl",
            "info": "Varatiiviste Kaasuvirtausmittarille: Asetyleeni. Tiiviste tulisi turvallisuussuositusten mukaan vaihtaa aina, kun mittaria käytetään irti. ",
            "main_category": "7. Kaasuvirtausmittarit ja suojakaasut",
            "down_category": "Varatiiviste"
        }
    ]
};
export default tuoteHakuItems;
