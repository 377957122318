import React, {useEffect, useState} from 'react';
import './tuotehaku.css'
import tuoteHakuItems from "../../Tuotehakuitems"
import Spinner from "../Spinner";
import {TuotehakuPopUp} from "./hooks/TuotehakuPopUp";
import {useQueryClient} from "react-query";

function Tuotehaku() {
    const [inputText, setInputText] = useState('');
    const [items, setItems] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [width, setWidth] = useState(0);
    const [matchResultsLength, setMatchResultsLength] = useState(undefined);
    const [tuotehakuItems, setTuotehakuItems] = useState(undefined)
    const queryClient = useQueryClient();
    let data = queryClient.getQueryData("getItems");
    

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        setWidth(window.innerWidth)
        if(!data) {
            setTuotehakuItems(tuoteHakuItems.data)
        } else {
            setTuotehakuItems(data)
        }
        return function cleanup() {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [setWidth, setTuotehakuItems, data])

    function updateDimensions() {
        setWidth(window.innerWidth);
    }

    function checkValidSearch(itm, type, inputText) {
        if (itm.hasOwnProperty(type)) {
            if (itm[type].toLowerCase().match(inputText)) {
                return true
            }
        }
        return false
    }

    function findSearch() {
        setLoading(true)
        
        
        if (inputText.length > 0) {
            let inputTxt = inputText.toLowerCase();
            let newList = [];
            tuotehakuItems.forEach((item) => {
                if (
                    checkValidSearch(item, "code", inputTxt) ||
                    checkValidSearch(item, "ean_code", inputTxt) ||
                    checkValidSearch(item, "text", inputTxt) ||
                    checkValidSearch(item, "info", inputTxt) ||
                    checkValidSearch(item, "main_category", inputTxt) ||
                    checkValidSearch(item, "down_category", inputTxt)
                ) {
                    newList.push(item);
                }
            });

            setItems(newList)
            setMatchResultsLength(newList.length)

        } else {
            alert("Kirjoita ensin ean- tai tuotekoodi tai tavaraa kohdentuva teksti");
            setLoading(prevState => !prevState)
            return
        }

        setTimeout(() => {
            setLoading(prevState => !prevState)
        }, 500)
    }


    if(isLoading) {
        return(
            <div className={"spinnerDiv"}>
            <p> Loading </p>
            <Spinner/>
        </div>
        )
    }

    return (
            <div className={"kuvatContentMargin"}>
                <div className={"kuvatContent"}>
                    <div className={"tuoteHakuContent"}>
                        <div className={"logoDiv"}>
                            <img className="logoHaku"
                                 src={"https://palokarkiproducts-kuvat.s3.eu-north-1.amazonaws.com/Palok%C3%A4rki_logo_iso_123kB.jpg"}
                                 alt=""/>
                        </div>
                        <div className="buttonInside">
                            <form action="">
                                <div className="embed-submit-field">
                                    <input onChange={e => setInputText(e.target.value)} className={"tuotehakuInput"} type="text"
                                           placeholder="Etsi tuotetta"/>
                                    <input alt={''} src={"/search.svg"} className={'tuotehakuButton'} type={'image'}
                                           onClick={() => {
                                               findSearch()
                                           }}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <TuotehakuPopUp
                        items={items}
                        matchResultsLength={matchResultsLength}
                        width={width}
                    />
                </div>
            </div>
        );
}

export default Tuotehaku