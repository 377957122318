var menuItems = {
    "data" : [
        {
            "id": "tuotteet",
            "name": "Tuotteet",
            "children": [
                {
                    "id": "Hitsauspuikot ja -juotteet",
                    "name": "Hitsauspuikot ja -juotteet",
                    "url": "/tuotteet/Hitsauspuikot ja -juotteet",
                },
                {
                    "name": "Mig-langat",
                    "id": "MIG-langat",
                    "url": "/tuotteet/Mig-langat",
                },
                {
                    "name": "Tig- ja kaasuhitsauslangat",
                    "url": "/tuotteet/Tig- ja kaasuhitsauslangat",
                    "id": "Tig- ja kaasuhitsauslangat"
                },
                {
                    "name": "Hitsaustarvikkeet",
                    "url": "/tuotteet/Hitsaustarvikkeet",
                    "id": "hitsaustarvikkeet"
                },
                {
                    "name": "Hitsausmaskit ja -lasit",
                    "url": "/tuotteet/Hitsausmaskit ja -lasit",
                    "id": "Hitsausmaskit ja -lasit"
                },
                {
                    "name": "Mig-polttimet",
                    "id": "Mig-polttimet",
                    "url": "/tuotteet/Mig-polttimet"
                },
                {
                    "name": "Kaasuvirtausmittarit ja suojakaasut",
                    "id": "Kaasuvirtausmittarit ja suojakaasut",
                    "url": "/tuotteet/Kaasuvirtausmittarit ja suojakaasut",
                },
                {
                    "name": "Kiinnitystarvikkeet",
                    "url": "/tuotteet/Kiinnitystarvikkeet",
                    "id": "Kiinnitystarvikkeet"
                },
                {
                    "name": "Kaindl terät ja porakoneteline",
                    "url": "/tuotteet/Kaindl terät ja porakoneteline",
                    "id": "Kaindl terät ja porakoneteline"
                },
            ]
        },
    ]
};
export default menuItems;
