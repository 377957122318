import React from 'react';
import "../styles/kuvat.css";
import 'react-table/react-table.css'

export function ImagePopUp(props) {

    function getImageAddress() {
        return props.popUpData["imageAddress"] ? props.popUpData["imageAddress"] : "";
    }

    function getItemDescription() {
        return props.popUpData["imageDescription"]
            ? <div>
                <b className={"boldedItem"}>Kuvaus</b>
                <br/>
                <p className={"imageDescription"}>{props.popUpData["productDescription"]}</p>
            </div>
            : null
    }

    if (!props.popUpOpen) {
        return null;
    }

    let productImage = getImageAddress();
    return (
        <div className={"popup-menu"}>
            <div className={"popup-container"}>
                <div className={`popup-textcontain`}>
                    <div className={'borderedItems'}>
                        <b className={'boldedItem'}>Tuotekoodi</b>
                        <br/>
                        <p className={"popUpText"}>{props.popUpData["productCode"]}</p>
                        <b className={'boldedItem'}>Ean koodi</b>
                        <br/>
                        <p className={"popUpText"}>{props.popUpData["eanDescription"]}</p>

                        <b className={'boldedItem'}>Tuoteinfo</b>

                        <p className={"popUpText"}>{props.popUpData['imageDescription']}</p>
                        {getItemDescription()}
                    </div>
                    <h2>
                        <p onClick={() => {
                            closePopUp()
                        }} className={"close"}>X</p></h2>
                    <div className={"download-image-container"}>
                        <img className={"imageLarge"} src={productImage} alt=""/>
                        <a href={productImage} target="_blank" rel="noopener noreferrer">
                            <img className={"downloadImg"} src={"/generatedDownload.svg"} alt={""}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className={"overlay"} onClick={() => {
                closePopUp()
            }}/>
        </div>
    )

    function closePopUp() {
        props.setPopUpData({})
        props.togglePopUp(false)
    }
}