import React from 'react';
import "./styles/yhteystiedot.css";

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const unSupportedWords =
    [
        "porn", "!!!!", "????", "singles", "XXX", "near you", "Earn $",
        "Earn extra cash", "$$$", "Online marketing", "spam",
        "gay", "lesbian", "hot new pictures", "credit", "dating", "sex", "cock"
    ]

const unSupportedEmailDomains = [".fun"];

const finnishBadWords = ["perkele", "saatana", "vittu", "homo", "perkele", "helvetti", "kyrpä", "perse", "pillu"]

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", message: "", tel: "", submitted: false, };
    }

    goHome () {
        alert("Sähköposti lähetetty");
        this.setState({
            name: "",
            email: "",
            message: "",
            tel: "",
            submitted: true,
        });
    }

    formValidation() {
        if(this.state.name.length < 3) {
            alert('Ei kelvollinen nimi');
            return false
        }
        if(!this.state.email.includes('@')) {
            alert('Ei kelvollinen email');
            return false
        }
        if(this.state.message.length < 10) {
            alert("Viesti on liian lyhyt")
            return false
        }

        let foundUnSupportedEmail = unSupportedEmailDomains.find(itm => this.state.email.toLowerCase().includes(itm));
        if(foundUnSupportedEmail) {
            alert(`${foundUnSupportedEmail} ei ole kelvollinen email domain.`)
            return false
        }

        let foundUnSupportedWord = unSupportedWords.find(itm => this.state.message.toLowerCase().includes(itm));
        if(foundUnSupportedWord) {
            alert(`Sana: ${foundUnSupportedWord} ei ole kelvollinen viestissä.`)
            return false
        }

        let foundFinnishWord = finnishBadWords.find(itm => this.state.message.toLowerCase().includes(itm));
        if(foundFinnishWord) {
            alert(`Sana: ${foundFinnishWord} ei ole kelvollinen viestissä.`)
            return false
        }
        return true;
    }

    handleSubmit = e => {
        if(this.formValidation()) {
            fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: encode({"form-name": "contact", ...this.state})
            })
                .then(() => this.goHome())
                .catch(error => alert(error));
        }
        e.preventDefault();
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, message, tel } = this.state;
        if(!this.state.submitted) {
            return (
                <form id="contact-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label className={"label-text"}>
                            Nimi: <input className="form-control" type="text" name="name" value={name} onChange={this.handleChange} />
                        </label>
                    </div>
                    <div className="form-group">
                        <label className={"label-text"}>
                            Sähköposti: <input className="form-control" type="email" name="email" value={email} onChange={this.handleChange} />
                        </label>
                    </div>
                    <div className="form-group">
                        <label className={"label-text"}>
                            Puhelinnumero: <input type={'number'} className="form-control" name="tel" value={tel} onChange={this.handleChange} />
                        </label>
                    </div>
                    <div className="form-group">
                        <label className={"label-text"}>
                            Viesti: <textarea className="form-control" name="message" value={message} rows={"5"} onChange={this.handleChange} />
                        </label>
                    </div>
                    <div className={"submit-div"}>
                        <button className="btn-primary" type="submit">Lähetä</button>
                    </div>
                </form>
            );
        } else { return <h1 className={"thanks-submit"}>Kiitos viestistäsi!</h1>}
    }
}

export default ContactForm;
