import React, {useState, useEffect} from "react";
import "../new.css";
import {CSSTransition} from "react-transition-group";
import menuItems from "./menuItems";
import NavBar from "./NavBar";
import {Link} from "react-router-dom";
import {FaEllipsisH} from 'react-icons/fa';

export default function New() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isNavOpen, setNavState] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 1150px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };
    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
        hideItemMenu();
    };
    const hideItemMenu = () => {
        setNavState(false);
    };
    const changeNavState = () => {
        setNavState(!isNavOpen);
    };
    const hideAllElements = () => {
        setNavState(false);
        setNavVisibility(false);
    };
    return (
        <header className="Header">
            <Link className={"linkToHome"} to={"/home"} onClick={hideAllElements}>
                <img className="logoNav"
                     src={"https://palokarkiproducts-kuvat.s3.eu-north-1.amazonaws.com/Palok%C3%A4rki_logo_iso_123kB.jpg"}
                     alt=""/></Link>
            <CSSTransition
                in={!isSmallScreen || isNavVisible}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >
                <nav className="Nav">
                    <Link className={"liItems"} to={"/home"} onClick={toggleNav}>Etusivu</Link>
                    <NavBar
                        list={menuItems}
                        onChange={toggleNav}
                        navOpen={isNavOpen}
                        changeNav={changeNavState}
                        hideMenu={hideItemMenu}
                    >
                    </NavBar>
                    <Link className={"liItems"} to={"/tuotehaku"} onClick={toggleNav}>Tuotehaku</Link>
                    <Link className={"liItems"} to={"/yhteystiedot"} onClick={toggleNav}>Ota yhteyttä</Link>
                </nav>
            </CSSTransition>
            <button onClick={toggleNav} className="menuButton">
                <i className={"iLogo"}><FaEllipsisH/></i>
            </button>
        </header>
    );
}