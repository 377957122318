import React, {useState} from 'react';
import "../tuotehaku.css"
import 'react-table/react-table.css'
import {ImagePopUp} from "../../Kuvat/hooks/ImagePopUp";
import {
    checkPagination, columns,
    getTableLength,
    makeDescription,
    makeEan,
    makePrDr,
    makeProductCode, makeWebUrlCodeEan,
    makeWebUrlFromList
} from "../../PalokarkiFunctions";
import ReactTable from "react-table";

export function TuotehakuPopUp(props) {
    const [popUpOpen, togglePopUp] = useState(false);
    const [popUpData, setPopUpData] = useState({})
    const columns2 = [
        {
            Header: 'Tuotteet',
            Cell: row => {
                let webUrl = makeWebUrlCodeEan(row.original.code, row.original.ean_code);
                return (
                    <div className={"items"}>
                        <span className="class-for-name">{row.original.text}</span>
                        <br/>
                        <div className={'grid-container'}>
                            <button onClick={() => {
                                openPopUpFunc(webUrl, row.original.text, row.original.ean_code, row.original.info, row.original.code)
                            }} className={"class-for-button"}>Lue lisää
                            </button>
                            <span className="class-for-description2"><b>EAN-koodi:</b> {row.original.ean_code}</span>
                            <span className="class-for-description"><b>Tuotekoodi:</b> {row.original.code}</span>
                        </div>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <ImagePopUp
                popUpOpen={popUpOpen}
                popUpData={popUpData}
                setPopUpData={setPopUpData}
                togglePopUp={togglePopUp}
            />
            {renderItemsToList()}
        </>
    )

    function renderItemsToList() {
        if (props.items && props.items.length > 0) {
            let itmUrl = makeWebUrlFromList(props.items);
            let itmDescription = makeDescription(props.items);
            let productCode = makeProductCode(props.items);
            let itmPrDr = makePrDr(props.items);
            let itmEan = makeEan(props.items);
            let tableLength = getTableLength(props.items.length);
            return (
                <div>
                    <h1 className={"matchResults"}>Löytyi {props.matchResultsLength} tuotetta</h1>
                    <div className={'imageContent'}>
                        <div id="partners">
                            <div className={"outerScroll"}>
                                {getImages(itmUrl, itmDescription, itmEan, itmPrDr, productCode)}
                            </div>
                        </div>
                        <div id={"largerTable"}>
                            <div className={"itemsTable"}>
                                <ReactTable
                                    defaultPageSize={tableLength}
                                    data={props.items}
                                    getTrProps={(state, rowInfo) => ({
                                        onClick: () => openPopUpFunc(makeWebUrlCodeEan(rowInfo.original.code, rowInfo.original.ean_code), rowInfo.original.text, rowInfo.original.ean_code, rowInfo.original.info, rowInfo.original.code)
                                    })}
                                    columns={checkColumn()}
                                    showPagination={checkPagination(tableLength)}
                                    previousText={"Edellinen"}
                                    nextText={"Seuraava"}
                                />
                            </div>
                        </div>
                    </div>
                </div>)
        }
    }

    function getImages(itm, itmDescription, ean, prDr, productCode) {
        return itm.map((itmUrl, i) => {
            return (<div key={i} className="logo-image">
                <img
                    onClick={() => {
                        openPopUpFunc(itmUrl, itmDescription[i], ean[i], prDr[i], productCode[i])
                    }} className={"tuoteKuvat"} src={itmUrl} placeholder={'/img/hourglass.svg'} alt=""/></div>);
        });
    }

    function openPopUpFunc(itm, itmDescription, ean, prDe, productCode) {
        let obj = {
            imageAddress: itm,
            imageDescription: itmDescription,
            eanDescription: ean,
            productDescription: prDe,
            productCode: productCode,
        }
        setPopUpData(obj);
        togglePopUp(true);
    }

    function checkColumn() {
        return props.width > 900 ? columns : columns2
    }

}