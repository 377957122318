import React from 'react';
import './App.css';
import {useQuery} from "react-query";
import {withRouter} from "react-router-dom";

import Routes from "./Routes";
import New from "./components/New";
import "./components/styles/header.css";
import {getItems, getItems2} from "./getItems";

function App() {
    useQuery("getItems", getItems)
    useQuery("getItems2", getItems2)
    return (
        <div className={'bck'}>
            <New/>
            <Routes/>
        </div>
    );

}

export default withRouter(App);
