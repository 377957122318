import React, {Component} from 'react'
import "./styles/navbar.css";
import {Link} from 'react-router-dom'

import "./styles/navbar.css";

class NavBar extends Component {

    renderNavBar() {
        if (this.props.list) {
            return this.props.list.data.map(it => <ul key={it.id}>{this.checkChildren(it)}</ul>)
        }
    }

    renderHome(itm) {
        return (<div onClick={() => {
            this.hideChildren()
        }}>
            <Link onClick={() => {
                this.hideChildrenChildren()
            }} to={{
                pathname: itm.url,
                state: {
                    d: true,
                }
            }} className={"liItems"} key={itm}>{itm.name}</Link>
        </div>)
    }

    togNav() {
        this.props.onChange();
    }

    hideChildren() {
        this.togNav();
    }

    openMenu() {
        this.props.changeNav();
    }

    plusMinusSign(itmName) {
        if (this.props.navOpen) {
            return (
                <div className={"mainLiElement"} onClick={() => {
                    this.openMenu()
                }}>
                    <span className={"plus-sign"}>-</span>
                    {itmName.name}

                </div>
            )
        } else {
            return (
                <div className={"mainLiElement"} onClick={() => {
                    this.openMenu()
                }}>
                    <span className={"plus-sign"}>+</span>
                    {itmName.name}

                </div>)
        }
    }

    returnOverlay() {
        if (this.props.navOpen) {
            return (<div className={"overlayNavBar"} onClick={() => {
                this.hideChildrenChildren();
            }}/>)
        }
    }

    checkChildren(itm) {
        if (itm.hasOwnProperty('children')) {
            return (<ul className={"liItems2"}>
                {this.plusMinusSign(itm)}
                {this.renderElements(itm)}
                {this.returnOverlay()}
            </ul>)
        } else {
            return this.renderHome(itm);
        }
    }

    hideChildrenPrev(item) {
        this.togNav();
        this.setState({
            children: false,
        });
        this.setState(prevState => (
            {[item]: !prevState[item]}
        ))
    }

    renderLinks(item) {
        return (<div className={"itemMenuCategory"} key={item.id} onClick={() => {
            this.hideChildrenChildren()
        }}><Link to={item.url} className={"itemCategory"} key={item}>{item.name}</Link></div>)

    }

    hideChildrenChildren() {
        this.togNav();
    }

    renderElements(itm) {
        if (this.props.navOpen) {
            return itm.children.map((item, i) => {
                return this.renderLinks(item, item.id);
            });
        }
    }

    render() {
        return (
            <div className={"navBar"}>
                {this.renderNavBar()}
            </div>
        )
    }
}

export default NavBar