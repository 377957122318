import React from 'react'
import './spinner.css'
class Spinner extends React.Component {
    render() {
        const style = {
            width: '50px',
            height: '50px',
            borderColor: 'grey'
        };
        return (
            <div className='react-spinner' style={style}/>
        )
    }
}
export default Spinner