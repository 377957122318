import React, {Component} from 'react';
import "./styles/yhteystiedot.css";
import ContactForm from "./ContactForm";

class Yhteystiedot extends Component {

    async getProducts() {
        fetch('http://localhost:4000/products', )
            .then(response => response.json())
            .then(response => this.setState({products: response.data}))
            .catch(err => console.error(err))

    }


    netlifyForm() {
        return(
            <ContactForm/>
        )
    }

    render() {
        return (

            <div className={"zIndexZero"}>

                <img className="logoHome"
                     src={"https://palokarkiproducts-kuvat.s3.eu-north-1.amazonaws.com/Palok%C3%A4rki_logo_iso_123kB.jpg"}
                     alt=""/>
                <h1 className={"yth1"}>Ota yhteyttä</h1>
                <p className={"ytp"}>Puh. +358 400 686 877</p>
                {this.netlifyForm()}
            </div>
        )
    }
}

export default Yhteystiedot;
