export const getItems = async () => {
    const response = await fetch("https://palokarkiadministrator.herokuapp.com/api/items/").then(

    ).catch(err => console.log(err))
    return response.json()
}

function sortTuoteItems(data) {
    let arr = [{"test": "123"}];
    data.forEach((item) => {
        let found = arr.findIndex(itm =>
            itm["obj_id"] === item["main_category"] &&
            itm["obj_code"] === item["down_category"]
        )
        if(found && found > -1) {
            arr[found]["obj_items"].push(item)
        } else {
            let obj = {
                "obj_id": item["main_category"],
                "obj_code": item["down_category"],
                "obj_items": [item]
            }
            arr.push(obj)
        }
    })
    arr.shift()
    return arr;
}

export const getItems2 = async () => {
    let response;
    await fetch("https://palokarkiadministrator.herokuapp.com/api/items/")
        .then(response => response.json())
        .then(data => response = sortTuoteItems(data)
        )
        .catch(err => console.log(err))
    return response
}