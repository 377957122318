import React, {useState} from 'react';
import "../styles/kuvat.css";
import 'react-table/react-table.css'
import {
    checkPagination, columns,
    getTableLength,
    makeDescription,
    makeEan,
    makePrDr,
    makeProductCode, makeWebUrlCodeEan,
    makeWebUrlFromList
} from "../../PalokarkiFunctions";
import ReactTable from "react-table";
import Spinner from "../../Spinner";
import {ImagePopUp} from "./ImagePopUp";

export function ItemsToList(props) {
    const [imageLoaded, setImageLoaded] = useState('loading');
    const [popUpOpen, togglePopUp] = useState(false);
    const [popUpData, setPopUpData] = useState({})

    const columns2 = [
        {
            Header: 'Tuotteet',
            Cell: row => {
                let webUrl = makeWebUrlCodeEan(row.original.code ,row.original.ean_code);
                return (
                    <div className={"items"}>
                        <span className="class-for-name">{row.original.text}</span>
                        <br/>
                        <div className={'grid-container'}>
                            <button onClick={() => {
                                openPopUpFunc(webUrl, row.original.text, row.original.ean_code, row.original.info, row.original.code)
                            }} className={"class-for-button"}>Lue lisää
                            </button>
                            <span className="class-for-description2"><b>EAN-koodi:</b> {row.original.ean_code}</span>
                            <span className="class-for-description"><b>Tuotekoodi:</b> {row.original.code}</span>
                        </div>
                    </div>
                )
            }
        }
    ];

    if(!props.items || props.items.length < 1) {return null}

    return(<div>
        <ImagePopUp
            popUpOpen={popUpOpen}
            popUpData={popUpData}
            setPopUpData={setPopUpData}
            togglePopUp={togglePopUp}
        />
        {renderItems()}
    </div>)

    function renderItemsForUi(itm, i) {
        let itmUrl = makeWebUrlFromList(itm);
        let itmDescription = makeDescription(itm);
        let itmEan = makeEan(itm);
        let itmPrDr = makePrDr(itm);
        let tableLength = getTableLength(itm.items.length);
        let productCode = makeProductCode(itm);
        return (
            <div className={'imageContent'} key={i}>
                <div id="partners">
                    <div className={"outerScroll"}>
                        {getImages(itmUrl, itmDescription, itmEan, itmPrDr, productCode)}
                    </div>
                </div>
                <div id={"largerTable"}>
                    <div className={"itemsTable"} key={i}>
                        <ReactTable
                            defaultPageSize={tableLength}
                            data={itm.items}
                            columns={checkColumn()}
                            getTrProps={(state, rowInfo) => ({
                                onClick: () => openPopUpFunc(makeWebUrlCodeEan(rowInfo.original.code, rowInfo.original.ean_code), rowInfo.original.text, rowInfo.original.ean_code, rowInfo.original.info, rowInfo.original.code)
                            })}
                            previousText={"Edellinen"}
                            nextText={"Seuraava"}
                            showPagination={checkPagination(tableLength)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function renderItems() {
        let found = props.items.find(itm => itm['active']);
        return props.items.map((itm, i) => {
            if (!found) {
                return renderItemsForUi(itm, i)
            }
            if (!itm.active) {
                return null;
            }
            return renderItemsForUi(itm, i)
        });
    }

    function checkColumn() {
        return props.width > 900 ? columns : columns2
    }

    function getImages(itm, itmDescription, ean, prDr, productCode) {
        return itm.map((itmUrl, i) => {
            return (<div key={i} className="logo-image">{(() => {
                return imageLoaded === 'loading' ? <Spinner/> : null
            })()}
                <img onLoad={setFileStatus.bind(this, 'loaded')}
                     onError={setFileStatus.bind(this, 'failed to load')}
                     onClick={() => {
                         openPopUpFunc(itmUrl, itmDescription[i], ean[i], prDr[i], productCode[i])
                     }} className={"tuoteKuvat"} src={itmUrl} placeholder={'/img/hourglass.svg'} alt=""/>
            </div>);
        });
    }

    function setFileStatus(status) {
        setImageLoaded(status)
    }

    function openPopUpFunc(itm, itmDescription, ean, prDe, productCode) {
        let obj = {
            imageAddress: itm,
            imageDescription: itmDescription,
            eanDescription: ean,
            productDescription: prDe,
            productCode: productCode,
        }
        setPopUpData(obj);
        togglePopUp(true);
    }
}