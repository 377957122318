import React, {useEffect, useState} from 'react';
import "../styles/kuvat.css";
import 'react-table/react-table.css'
import {getUnique} from "../../PalokarkiFunctions";
import {ItemsToList} from "./ItemsToList";
import {FilteredList} from "./FilteredList";
import tuoteItems from "../../tuoteItems";
import Spinner from "../../Spinner";
import {useQueryClient} from "react-query";

export function PicturesPage(props) {

    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState(undefined);
    const [finalFilterableList, setFinalFilterableList] = useState(undefined)
    const [filterStatus, setFilterStatus] = useState(false);
    const [filterableList, setFilterableList] = useState(undefined)
    const [filterItemSelected, setFilterItemSelected] = useState(false)
    const queryClient = useQueryClient();
    let data = queryClient.getQueryData("getItems2");

    useEffect(() => {
        const setLoadingTrue = () => {
            setLoading(true)
        };
        const setLoadingFalse = () => {
            setTimeout(() => {
                setLoading(false)
            }, 300)

        };
        const renderItemsById = () => {
            let items;
            if(!data) {
                items = tuoteItems.data;
            } else {
                items = data;
            }
            if (props.itemId && items) {
                let newList = [];
                items.forEach((itm) => {
                    if (itm.obj_id === props.itemId) {
                        newList.push({
                            objCode: itm.obj_code,
                            objItem: itm.obj_items,
                        });
                    }

                });
                let finalList = getItemsToTable(newList)
                setItems(finalList)
                setFilterableList(finalList)
                setFilterStatus(false)
                setFilterItemSelected(false)
            }
        }

        setLoadingTrue();
        renderItemsById();
        setLoadingFalse();
    }, [data, props.itemId]);


    function getItemsToTable(list) {
        if (!list) return null;
        let finalList = [];
        list.forEach((itm, i) => {
            finalList.push({
                active: false,
                name: itm.objCode,
                items: itm.objItem,
                filterableList: itm.objItem
            });
        });
        return finalList;
    }

    function filterItemsInList() {
        if (!filterStatus) {
            setFilterItemSelected(true)
        }
        let newList = [];
        filterableList.forEach((itm) => {
            newList.push({
                name: itm.name,
                active: itm.active,
            });
        });
        setFilterStatus(prev => !prev);
        setFinalFilterableList(getUnique(newList, "name"));
    }

    if (isLoading) {
        return (
            <div className={"spinnerDiv"}>
                <p> Loading </p>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={"kuvatContentMargin"}>
            <div className={"kuvatContent"}>
                <div className={"dropdown"}>
                    {checkIfSomeItemActive()}
                    <h2 className={'filterButton'} onClick={() => {
                        filterItemsInList()
                    }}>Suodata
                        {filterStatus
                            ? <span className={'plusSign'}>-</span>
                            : <span className={'plusSign'}>+</span>
                        }
                    </h2>

                </div>
                <div className={"filterList"}>
                    <FilteredList
                        filterStatus={filterStatus}
                        finalFilterableItems={finalFilterableList}
                        activateCategory={activateCategory}
                    />
                </div>
                <ItemsToList
                    items={items}
                    width={props.width}
                />
            </div>
        </div>
    )

    function checkIfAnyItemIsActive() {
        let newList = [].concat(items);
        for (let i = 0; i < newList.length; i++) {
            if (!newList[i].active) {
                return false
            }
        }
        return true;
    }

    function changeAllActive() {
        let newList = [].concat(items);
        newList.forEach((item) => {
            item.active = false;
        });
        setLoading(true)
        setFilterStatus(false)
        setFilterItemSelected(false)

        setTimeout(() => {
            setLoading(false)
            setItems(newList)
        }, 200)
    }

    function deActive() {
        let newList = [].concat(items);
        newList.forEach((item) => {
            item.active = false;
        });
        setItems(newList);
    }

    function activateCategory(itm) {
        if (checkIfAnyItemIsActive()) {
            deActive();
        }

        let newList = [].concat(items);
        newList.forEach((item) => {
            if (itm.name === item.name) {
                item.active = !item.active
            }
        });

        setLoading(true);
        setFinalFilterableList(getUnique(newList, 'name'))
        setFilterItemSelected(true)
        setFilterStatus(true);


        setTimeout(() => {
            setLoading(false);
            setItems(newList);
        }, 200);

    }

    function checkIfSomeItemActive() {
        if (!items) return;
        let found = items.find(itm => itm['active']);
        if (!found) return (<div className={'filterButton-deactive'}/>)
        return filterItemSelected
            ? <h2 onClick={() => {
                changeAllActive()
                setFilterStatus(false)
            }} className={'filterButton'}>Näytä kaikki</h2>
            : <div className={'filterButton-deactive'}/>
    }
}