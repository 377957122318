import React from 'react';
import "./styles/Home.css";

function Home() {
    return (
        <div className={"zIndexZero"}>
            <img className="logoHome"
                 src={"https://palokarkiproducts-kuvat.s3.eu-north-1.amazonaws.com/Palok%C3%A4rki_logo_iso_123kB.jpg"}
                 alt=""/>
            <p className={"homeText"}>Olemme hitsaustarvikkeiden ja hitsauslisäaineiden yli 30 vuotta alalla toiminut
                maahantuonti- ja tukkuliike.
                Maahantuonti sisältää laajan ja laadukkaan valikoiman hitsaustarvikkeita ja erikoistuoteryhmiä.
            </p>
        </div>
    )
}

export default Home;