import React from 'react';
import "../styles/kuvat.css";
import 'react-table/react-table.css'


export function FilteredList(props) {
    return props.filterStatus && props.finalFilterableItems
    ? props.finalFilterableItems.map((itm, i) => {
        return itm.active
            ?
            <div onClick={() => {
                props.activateCategory(itm)
            }} className={"dropdown-content-active"} key={i}>{itm.name}</div>
            :
            <div className={"dropdown-content-not-active"} onClick={() => {
                props.activateCategory(itm)
            }} key={i}>{itm.name}</div>
    })
    : <div/>
}