import React from "react";
const webUrl = "https://palokarkiproducts-kuvat.s3.eu-north-1.amazonaws.com/";

export function makeWebUrlFromList(list) {
    if (list.hasOwnProperty("items")) {
        return list.items.map((item) => {
            return webUrl + item.code.toString() + "_" + item.ean_code.toString() + ".jpg";
        });
    }
    if (list) {
        return list.map((item) => {
            return "https://palokarkiproducts-kuvat.s3.eu-north-1.amazonaws.com/" + item.code.toString() + "_" + item.ean_code.toString() + ".jpg";
        });
    }
}

export function makeWebUrlCodeEan(code, ean) {
    return webUrl + code.toString() + "_" + ean.toString() + ".jpg";

}

export function makeEan(itm) {
    if (itm.hasOwnProperty("items")) {
        return itm.items.map((item) => {
            return item.ean_code;
        });
    }
    if (itm) {
        return itm.map((item) => {
            return item.ean_code;
        });
    }
}

export function makePrDr(itm) {
    if (itm.hasOwnProperty("items")) {
        return itm.items.map((item) => {
            return item.info;
        });
    }
    if (itm) {
        return itm.map((item) => {
            return item.info;
        });
    }
}

export function makeProductCode(itm) {
    if (itm.hasOwnProperty("items")) {
        return itm.items.map((item) => {
            return item.code;
        });
    }
    if (itm) {
        return itm.map((item) => {
            return item.code;
        });
    }
}

export function getUnique(arr, comp) {
    return arr.map(e => e[comp]).map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e]).map(e => arr[e])
}

export function makeDescription(itm) {
    if (itm.hasOwnProperty("items")) {
        return itm.items.map((item) => {
            return item.text;
        });
    }
    if (itm) {
        return itm.map((item) => {
            return item.text;
        });
    }
}

export function checkPagination(tableLng) {
    return tableLng > 4;
}

export function getList(item) {
    let list = [];
    Object.keys(item).forEach((itm) => {
        list.push(item[itm]);
    });
    return list;
}

export function getFilterableList(item) {
    let list = [];
    Object.keys(item).forEach((itm) => {
        list.push(item[itm].name);
    });
    return list;
}

export function getTableLength(itmLength) {
    if(!itmLength) return 5;
    return itmLength > 5 ? 5 : itmLength;
}

export const columns = [
    {
        Header: "Tuotteet",
        Cell: row => {
            return (
                <div className={'headerImg'}>
                    {row.original.text}
                </div>
            )
        }
    },
    {
        Header: 'Tuotekoodi',
        width: 200,
        Cell: row => {
            return (
                <b className={"pointer"}> {row.original.code}</b>
            )
        }
    },
    {
        Header: 'EAN',
        width: 200,
        Cell: row => {
            return (
                <b className={"pointer"}> {row.original.ean_code}</b>
            )
        }
    },
];


