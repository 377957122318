import Home from "./components/Home/Home";
import Kuvat from "./components/Kuvat/Kuvat";
import Yhteystiedot from "./components/yhteystiedot/Yhteystiedot";
import React from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "./NotFound";
import Tuotehaku from "./components/Tuotehaku/Tuotehaku";

// eslint-disable-next-line import/no-anonymous-default-export
export default () =>
    <Switch>
        <Route path={"/home"} component={Home}/>
        <Route exact={false} path={"/tuotteet/:id"} component={Kuvat}/>
        <Route path={"/tuotehaku"} component={Tuotehaku}/>
        <Route path={"/yhteystiedot"} component={Yhteystiedot}/>
        <Route exact path={"/"} component={Home}/>
        { /* Finally, catch all unmatched routes */}
        <Route exact component={NotFound}/>
    </Switch>
;