import React, {useEffect, useState} from 'react';
import "./styles/kuvat.css";
import 'react-table/react-table.css'
import Spinner from "../Spinner";
import { PicturesPage } from "./hooks/PicturesPage";

function Kuvat(props){

    const [itemId, setitemId] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const itemId = props.match.params.id;
        if (itemId) {
            window.addEventListener('resize', updateDimensions);
            setWidth(window.innerWidth)
            setitemId(itemId)
        }

        setTimeout(function(){
            setLoading(false)
        }, 1000);
        return function cleanup() {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [props.match.params.id])
    
    function updateDimensions() {
        setWidth(window.innerWidth)
    }
    
    if(isLoading) {
        return (
            <div className={"spinnerDiv"}>
                <p> Loading </p>
                <Spinner/>
            </div>
        )
    }

    if(!itemId) {
        return null;
    }

    return(
        <PicturesPage
            itemId={itemId}
            width={width}
        />
    )
}

export default Kuvat;